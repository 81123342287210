import Link from 'next/link';
import { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface ColorProps extends HTMLAttributes<HTMLOrSVGElement> {
  className?: string;
  color?: string;
  variant?: 'full' | 'rounded' | 'text' | 'logintext';
}
export const Logo = ({
  className,
  color = 'black',
  variant = 'full',
  ...props
}: ColorProps) => {
  if (variant === 'text') {
    return (
      <Link href="/home">
        <svg
          className={twMerge('w-24 h-40', className)} //logo text
          viewBox="0 0  129 36"
          color={color}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1618.63,1096.88a14,14,0,0,0-2.83.65,7.65,7.65,0,0,0-3.18,2,5.21,5.21,0,0,0-1.24,1.64,6,6,0,0,0-.7,3,5.68,5.68,0,0,0,1.21,3.74,9,9,0,0,0,4.19,2.72c2.25.9,3.21,1.5,3.7,2.33a1.79,1.79,0,0,1,.26,1.15,2,2,0,0,1-.22,1.1c-.61,1.17-1.94,1.78-4.09,1.88a11,11,0,0,1-3.6-.41,12.24,12.24,0,0,1-3-1.35c-.31-.2-.45-.25-.48-.17s-.89,4.33-.87,4.36l.92.39c4.16,1.77,9.15,1.61,12.6-.43a9.17,9.17,0,0,0,2.58-2.49,6.78,6.78,0,0,0,.5-6.22c-.83-1.65-2-2.49-5.5-4a7.23,7.23,0,0,1-2.89-1.68,2.25,2.25,0,0,1,.74-3.73c1.85-.85,5.4-.61,8.11.57l.71.31.4-1.9c.22-1,.41-2,.41-2.06,0-.28-1.78-.89-3.64-1.22A26.37,26.37,0,0,0,1618.63,1096.88Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1713.8,1098.74s-.21,1-.47,2.22-.47,2.22-.47,2.26a5.84,5.84,0,0,1-1.4.08c-1.37,0-1.4,0-1.44.2s-.17.74-.3,1.39-.27,1.25-.29,1.33.07.17,1.34.19l1.39,0-1,4.69c-1,4.69-1,4.69-1,6s0,1.38.25,1.89c.65,1.33,1.91,1.89,4.2,1.9a6.6,6.6,0,0,0,2.89-.45,25,25,0,0,0,.63-3.12,1.26,1.26,0,0,0-.44,0,4.39,4.39,0,0,1-2.22-.08,1.46,1.46,0,0,1-.77-1.3c0-.17.44-2.39,1-4.93s1-4.65,1-4.68a13.79,13.79,0,0,1,2-.09l2,0,.3-1.41c.17-.78.31-1.47.31-1.52s-.47-.12-1.94-.14l-2,0,.46-2.11c.24-1.16.45-2.19.45-2.28s-.17-.16-2.21-.16C1714.79,1098.7,1713.8,1098.72,1713.8,1098.74Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1672.14,1103a10.43,10.43,0,0,0-8.22,10.77,7.09,7.09,0,0,0,5.49,7.07,7.65,7.65,0,0,0,2.5.25,12,12,0,0,0,2.41-.16,13.07,13.07,0,0,0,2.58-.78l.34-.17.41-1.83c.21-1,.38-1.84.37-1.84s-.38.13-.83.31c-2.54,1-4.79,1.18-6.39.59a4,4,0,0,1-2.48-3.44,2.35,2.35,0,0,1,0-.64s2.68,0,5.89,0H1680l.13-.68a15.52,15.52,0,0,0,.11-2.41,7.31,7.31,0,0,0-.76-3.93,6.35,6.35,0,0,0-2.32-2.42A8.58,8.58,0,0,0,1672.14,1103Zm2.51,3.48a2.92,2.92,0,0,1,1.4,1.68,6.9,6.9,0,0,1,.2,1.28l0,.83h-7.79l.09-.36a5.7,5.7,0,0,1,1.38-2.37,4.19,4.19,0,0,1,3.36-1.37A2.59,2.59,0,0,1,1674.65,1106.45Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1655.55,1103a7.12,7.12,0,0,0-3.18,1.54l-.55.51.11-.58c.07-.32.14-.72.18-.89l.06-.3-2.18,0-2.16,0-2.52,11.73c-1.39,6.44-2.51,11.78-2.52,11.84s.5.12,2.19.12c2,0,2.21,0,2.25-.17s.44-1.93.9-4.1.88-4,.91-4.07.13,0,.3.28c1.18,1.83,3.69,2.57,6.41,1.88,3.83-1,6.54-4.79,7-9.86.37-3.89-1-6.81-3.79-7.77a5.25,5.25,0,0,0-1.84-.26A9.47,9.47,0,0,0,1655.55,1103Zm1,3.58a2.68,2.68,0,0,1,1.38,1.6,10.07,10.07,0,0,1-.16,5.22c-.81,2.85-2.5,4.32-4.79,4.21-1.5-.08-2.27-.77-2.71-2.45a11.41,11.41,0,0,1,0-3.78c.74-3.24,2.8-5.24,5.22-5.05A4.22,4.22,0,0,1,1656.6,1106.6Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1700.76,1103a10.07,10.07,0,0,0-2.91,1,4.85,4.85,0,0,0-1.39,7.46,8.16,8.16,0,0,0,2.92,1.88c1.68.76,2.08,1,2.4,1.44a1.44,1.44,0,0,1,.18,1.59c-.29.59-.93.9-2.11,1a8.91,8.91,0,0,1-5.13-1.06,6.94,6.94,0,0,0-1-.43c0,.06-.82,3.84-.78,3.87a13.06,13.06,0,0,0,2.46.8,11.22,11.22,0,0,0,7.84-.54,5.68,5.68,0,0,0,3.1-3.75,7.44,7.44,0,0,0,.07-1.42,3.63,3.63,0,0,0-1.18-2.72,9.53,9.53,0,0,0-3.23-2,17.27,17.27,0,0,1-1.94-1,1.32,1.32,0,0,1,.1-2.34c1.34-.65,4.06-.37,6.48.68.41.17.31.39.78-1.81.18-.89.31-1.67.3-1.74a7.8,7.8,0,0,0-2.43-.82A19.14,19.14,0,0,0,1700.76,1103Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1728.34,1103l-.94.14a19.14,19.14,0,0,0-3.44.88s-.78,3.53-.78,3.67.16.06.63-.12a13.53,13.53,0,0,1,5-1c2.49,0,3.73.75,3.78,2.29v.6l-1.5.15c-5.4.55-8.49,1.67-10,3.6a5,5,0,0,0,1,6.92,6.25,6.25,0,0,0,3.9,1,5.78,5.78,0,0,0,2.52-.67,6.18,6.18,0,0,0,2.29-1.88c.11-.18.21-.31.24-.29a7.25,7.25,0,0,1-.2,1.1c-.14.58-.24,1.11-.24,1.18s.38.13,2,.13,2,0,2-.13a52.09,52.09,0,0,1,1.18-6.34c1.07-4.83,1.09-5,1.09-6.06a4.23,4.23,0,0,0-.48-2.2,4.39,4.39,0,0,0-1.58-1.72,7.94,7.94,0,0,0-4.75-1.23C1729.29,1103,1728.47,1103,1728.34,1103Zm3.94,9.33a16.24,16.24,0,0,1-1,2.74c-1.11,2.13-2.85,3.25-4.59,3a2.21,2.21,0,0,1-2.11-2.3c0-1.87,1.69-2.9,5.55-3.37C1731.41,1112.19,1732.28,1112.18,1732.28,1112.31Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1692.42,1103.35a6.57,6.57,0,0,0-3.29,2.22,6.81,6.81,0,0,1-.54.6s.22-1.07.58-2.67l0-.2H1687c-1.21,0-2.21,0-2.21,0s-.82,3.9-1.83,8.6-1.83,8.59-1.83,8.64a19.61,19.61,0,0,0,2.2.07c2,0,2.2,0,2.25-.17s.5-2.25,1-4.8c1-4.86,1.16-5.37,1.67-6.3.85-1.61,2.54-2.35,4.73-2.07a6.15,6.15,0,0,0,.84.09s.2-.87.41-1.9.41-2,.45-2.09,0-.21-.88-.2A7.39,7.39,0,0,0,1692.42,1103.35Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1749.55,1103.35a6.55,6.55,0,0,0-3.3,2.22,7.85,7.85,0,0,1-.53.6s.21-1.07.58-2.67l0-.2h-2.21c-1.21,0-2.2,0-2.2,0s-.83,3.89-1.83,8.6-1.83,8.58-1.83,8.63a15.8,15.8,0,0,0,2.19.08c2,0,2.21,0,2.26-.17s.49-2.25,1-4.8c1-4.86,1.16-5.37,1.67-6.3.85-1.61,2.54-2.35,4.72-2.07a6.37,6.37,0,0,0,.85.09s.2-.87.4-1.9.42-2,.45-2.09,0-.21-.87-.2A7.31,7.31,0,0,0,1749.55,1103.35Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1629.14,1103.5c-.42,1.79-2.32,11-2.4,11.63-.39,3.16.75,5.17,3.29,5.81a8.22,8.22,0,0,0,1.64.13,5.09,5.09,0,0,0,1.83-.22,6.67,6.67,0,0,0,2.63-1.48c.36-.34.65-.56.65-.48s-.08.48-.17.93l-.17.83h2.23c1.24,0,2.24,0,2.24-.05s3.52-16.69,3.61-17.1l0-.2h-4.46l-1,4.8c-1.07,5.06-1.35,6.12-1.9,7.11a5.06,5.06,0,0,1-1.93,1.92,4.23,4.23,0,0,1-2.33.34,1.79,1.79,0,0,1-1.6-1.59c-.15-.75,0-1.45,1.19-7.24.59-2.81,1.08-5.17,1.08-5.23s-.51-.11-2.21-.11h-2.2Z"
            transform="translate(-1615.10 -1091)"
          />
        </svg>
      </Link>
    );
  }
  if (variant === 'rounded') {
    return (
      <Link href="/home">
        <svg
          className={twMerge('w-8 h-8', className)} //logo
          viewBox="0 0 31.99 55"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <rect width="32" height="32" fill="#ffffff" />
          <path
            d="M1271,1392.57c-.32,1-.78,2.38-1,3.11l-.44,1.34h-3.25l-3.25,0,2.6,1.89c1.44,1,2.62,1.9,2.63,1.92s-.43,1.41-1,3.09-1,3.07-1,3.08,1.19-.82,2.62-1.86,2.62-1.9,2.65-1.89,1.22.85,2.65,1.89,2.6,1.89,2.61,1.88-.44-1.4-1-3.1-1-3.1-1-3.12l2.64-1.91,2.58-1.87-3.26,0-3.25,0-1-3c-.54-1.66-1-3.06-1-3.1S1271.3,1391.59,1271,1392.57Z"
            transform="translate(-1248.11 -1391)"
          />
          <path
            d="M1259.69,1397.18a14.72,14.72,0,0,0-8.3,3.89,11.2,11.2,0,0,0-3.22,6.71,17.23,17.23,0,0,0-.06,1.75,13.1,13.1,0,0,0,.11,2A9.25,9.25,0,0,0,1251,1417a16.39,16.39,0,0,0,4.81,3.21,38.68,38.68,0,0,0,4.17,1.64,61.8,61.8,0,0,1,7.42,2.93c2.93,1.46,4.41,2.89,4.89,4.74a6.77,6.77,0,0,1,.1,2,7.64,7.64,0,0,1-2.08,4.55,8.52,8.52,0,0,1-3.36,2.14c-.7.24-.53.28.48.12a13.85,13.85,0,0,0,6.5-2.85,11.35,11.35,0,0,0,4-8.44,10.79,10.79,0,0,0-1.23-6.07,8.89,8.89,0,0,0-1.5-2,16.16,16.16,0,0,0-4.73-3.32,51.56,51.56,0,0,0-6.2-2.5c-5.4-2-7.7-3.11-9.11-4.49a4.91,4.91,0,0,1-1.54-3.83,7,7,0,0,1,2-5,10,10,0,0,1,4.22-2.5c.65-.2.64-.19.61-.26A2.65,2.65,0,0,0,1259.69,1397.18Z"
            transform="translate(-1248.11 -1391)"
          />
          <path
            d="M1248.78,1432.21c0,6.2,0,6.54.09,6.51s14-6.44,14-6.49-13.56-6.34-14.05-6.53C1248.79,1425.68,1248.78,1426.6,1248.78,1432.21Z"
            transform="translate(-1248.11 -1391)"
          />
        </svg>
      </Link>
    );
  }
  if (variant === 'logintext') {
    return (
      <Link href="/home">
        <svg
          className={twMerge('w-300 h-auto', className)} //logo text
          viewBox="-105 0 350 50"
          color={color}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M1618.63,1096.88a14,14,0,0,0-2.83.65,7.65,7.65,0,0,0-3.18,2,5.21,5.21,0,0,0-1.24,1.64,6,6,0,0,0-.7,3,5.68,5.68,0,0,0,1.21,3.74,9,9,0,0,0,4.19,2.72c2.25.9,3.21,1.5,3.7,2.33a1.79,1.79,0,0,1,.26,1.15,2,2,0,0,1-.22,1.1c-.61,1.17-1.94,1.78-4.09,1.88a11,11,0,0,1-3.6-.41,12.24,12.24,0,0,1-3-1.35c-.31-.2-.45-.25-.48-.17s-.89,4.33-.87,4.36l.92.39c4.16,1.77,9.15,1.61,12.6-.43a9.17,9.17,0,0,0,2.58-2.49,6.78,6.78,0,0,0,.5-6.22c-.83-1.65-2-2.49-5.5-4a7.23,7.23,0,0,1-2.89-1.68,2.25,2.25,0,0,1,.74-3.73c1.85-.85,5.4-.61,8.11.57l.71.31.4-1.9c.22-1,.41-2,.41-2.06,0-.28-1.78-.89-3.64-1.22A26.37,26.37,0,0,0,1618.63,1096.88Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1713.8,1098.74s-.21,1-.47,2.22-.47,2.22-.47,2.26a5.84,5.84,0,0,1-1.4.08c-1.37,0-1.4,0-1.44.2s-.17.74-.3,1.39-.27,1.25-.29,1.33.07.17,1.34.19l1.39,0-1,4.69c-1,4.69-1,4.69-1,6s0,1.38.25,1.89c.65,1.33,1.91,1.89,4.2,1.9a6.6,6.6,0,0,0,2.89-.45,25,25,0,0,0,.63-3.12,1.26,1.26,0,0,0-.44,0,4.39,4.39,0,0,1-2.22-.08,1.46,1.46,0,0,1-.77-1.3c0-.17.44-2.39,1-4.93s1-4.65,1-4.68a13.79,13.79,0,0,1,2-.09l2,0,.3-1.41c.17-.78.31-1.47.31-1.52s-.47-.12-1.94-.14l-2,0,.46-2.11c.24-1.16.45-2.19.45-2.28s-.17-.16-2.21-.16C1714.79,1098.7,1713.8,1098.72,1713.8,1098.74Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1672.14,1103a10.43,10.43,0,0,0-8.22,10.77,7.09,7.09,0,0,0,5.49,7.07,7.65,7.65,0,0,0,2.5.25,12,12,0,0,0,2.41-.16,13.07,13.07,0,0,0,2.58-.78l.34-.17.41-1.83c.21-1,.38-1.84.37-1.84s-.38.13-.83.31c-2.54,1-4.79,1.18-6.39.59a4,4,0,0,1-2.48-3.44,2.35,2.35,0,0,1,0-.64s2.68,0,5.89,0H1680l.13-.68a15.52,15.52,0,0,0,.11-2.41,7.31,7.31,0,0,0-.76-3.93,6.35,6.35,0,0,0-2.32-2.42A8.58,8.58,0,0,0,1672.14,1103Zm2.51,3.48a2.92,2.92,0,0,1,1.4,1.68,6.9,6.9,0,0,1,.2,1.28l0,.83h-7.79l.09-.36a5.7,5.7,0,0,1,1.38-2.37,4.19,4.19,0,0,1,3.36-1.37A2.59,2.59,0,0,1,1674.65,1106.45Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1655.55,1103a7.12,7.12,0,0,0-3.18,1.54l-.55.51.11-.58c.07-.32.14-.72.18-.89l.06-.3-2.18,0-2.16,0-2.52,11.73c-1.39,6.44-2.51,11.78-2.52,11.84s.5.12,2.19.12c2,0,2.21,0,2.25-.17s.44-1.93.9-4.1.88-4,.91-4.07.13,0,.3.28c1.18,1.83,3.69,2.57,6.41,1.88,3.83-1,6.54-4.79,7-9.86.37-3.89-1-6.81-3.79-7.77a5.25,5.25,0,0,0-1.84-.26A9.47,9.47,0,0,0,1655.55,1103Zm1,3.58a2.68,2.68,0,0,1,1.38,1.6,10.07,10.07,0,0,1-.16,5.22c-.81,2.85-2.5,4.32-4.79,4.21-1.5-.08-2.27-.77-2.71-2.45a11.41,11.41,0,0,1,0-3.78c.74-3.24,2.8-5.24,5.22-5.05A4.22,4.22,0,0,1,1656.6,1106.6Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1700.76,1103a10.07,10.07,0,0,0-2.91,1,4.85,4.85,0,0,0-1.39,7.46,8.16,8.16,0,0,0,2.92,1.88c1.68.76,2.08,1,2.4,1.44a1.44,1.44,0,0,1,.18,1.59c-.29.59-.93.9-2.11,1a8.91,8.91,0,0,1-5.13-1.06,6.94,6.94,0,0,0-1-.43c0,.06-.82,3.84-.78,3.87a13.06,13.06,0,0,0,2.46.8,11.22,11.22,0,0,0,7.84-.54,5.68,5.68,0,0,0,3.1-3.75,7.44,7.44,0,0,0,.07-1.42,3.63,3.63,0,0,0-1.18-2.72,9.53,9.53,0,0,0-3.23-2,17.27,17.27,0,0,1-1.94-1,1.32,1.32,0,0,1,.1-2.34c1.34-.65,4.06-.37,6.48.68.41.17.31.39.78-1.81.18-.89.31-1.67.3-1.74a7.8,7.8,0,0,0-2.43-.82A19.14,19.14,0,0,0,1700.76,1103Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1728.34,1103l-.94.14a19.14,19.14,0,0,0-3.44.88s-.78,3.53-.78,3.67.16.06.63-.12a13.53,13.53,0,0,1,5-1c2.49,0,3.73.75,3.78,2.29v.6l-1.5.15c-5.4.55-8.49,1.67-10,3.6a5,5,0,0,0,1,6.92,6.25,6.25,0,0,0,3.9,1,5.78,5.78,0,0,0,2.52-.67,6.18,6.18,0,0,0,2.29-1.88c.11-.18.21-.31.24-.29a7.25,7.25,0,0,1-.2,1.1c-.14.58-.24,1.11-.24,1.18s.38.13,2,.13,2,0,2-.13a52.09,52.09,0,0,1,1.18-6.34c1.07-4.83,1.09-5,1.09-6.06a4.23,4.23,0,0,0-.48-2.2,4.39,4.39,0,0,0-1.58-1.72,7.94,7.94,0,0,0-4.75-1.23C1729.29,1103,1728.47,1103,1728.34,1103Zm3.94,9.33a16.24,16.24,0,0,1-1,2.74c-1.11,2.13-2.85,3.25-4.59,3a2.21,2.21,0,0,1-2.11-2.3c0-1.87,1.69-2.9,5.55-3.37C1731.41,1112.19,1732.28,1112.18,1732.28,1112.31Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1692.42,1103.35a6.57,6.57,0,0,0-3.29,2.22,6.81,6.81,0,0,1-.54.6s.22-1.07.58-2.67l0-.2H1687c-1.21,0-2.21,0-2.21,0s-.82,3.9-1.83,8.6-1.83,8.59-1.83,8.64a19.61,19.61,0,0,0,2.2.07c2,0,2.2,0,2.25-.17s.5-2.25,1-4.8c1-4.86,1.16-5.37,1.67-6.3.85-1.61,2.54-2.35,4.73-2.07a6.15,6.15,0,0,0,.84.09s.2-.87.41-1.9.41-2,.45-2.09,0-.21-.88-.2A7.39,7.39,0,0,0,1692.42,1103.35Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1749.55,1103.35a6.55,6.55,0,0,0-3.3,2.22,7.85,7.85,0,0,1-.53.6s.21-1.07.58-2.67l0-.2h-2.21c-1.21,0-2.2,0-2.2,0s-.83,3.89-1.83,8.6-1.83,8.58-1.83,8.63a15.8,15.8,0,0,0,2.19.08c2,0,2.21,0,2.26-.17s.49-2.25,1-4.8c1-4.86,1.16-5.37,1.67-6.3.85-1.61,2.54-2.35,4.72-2.07a6.37,6.37,0,0,0,.85.09s.2-.87.4-1.9.42-2,.45-2.09,0-.21-.87-.2A7.31,7.31,0,0,0,1749.55,1103.35Z"
            transform="translate(-1615.10 -1091)"
          />
          <path
            d="M1629.14,1103.5c-.42,1.79-2.32,11-2.4,11.63-.39,3.16.75,5.17,3.29,5.81a8.22,8.22,0,0,0,1.64.13,5.09,5.09,0,0,0,1.83-.22,6.67,6.67,0,0,0,2.63-1.48c.36-.34.65-.56.65-.48s-.08.48-.17.93l-.17.83h2.23c1.24,0,2.24,0,2.24-.05s3.52-16.69,3.61-17.1l0-.2h-4.46l-1,4.8c-1.07,5.06-1.35,6.12-1.9,7.11a5.06,5.06,0,0,1-1.93,1.92,4.23,4.23,0,0,1-2.33.34,1.79,1.79,0,0,1-1.6-1.59c-.15-.75,0-1.45,1.19-7.24.59-2.81,1.08-5.17,1.08-5.23s-.51-.11-2.21-.11h-2.2Z"
            transform="translate(-1615.10 -1091)"
          />
        </svg>
      </Link>
    );
  }
  return (
    <Link href="/home">
      <svg
        className={twMerge('w-auto h-8', className)}
        viewBox="0 0 105 25"
        xmlns="http://www.w3.org/2000/svg"
        color={color}
        {...props}
      >
        <path
          d="M1652.82,1107.38a7.88,7.88,0,0,0-1.56.36,4.14,4.14,0,0,0-1.76,1.09,2.81,2.81,0,0,0-.69.91,3.23,3.23,0,0,0-.39,1.66,3.14,3.14,0,0,0,.67,2.07,5,5,0,0,0,2.32,1.5c1.25.5,1.78.83,2,1.29a1,1,0,0,1,.14.64,1.14,1.14,0,0,1-.12.61c-.34.65-1.07,1-2.27,1a5.61,5.61,0,0,1-2-.22,6.53,6.53,0,0,1-1.64-.75c-.18-.11-.25-.14-.27-.09s-.49,2.39-.48,2.41l.51.22a8.06,8.06,0,0,0,7-.24,5,5,0,0,0,1.43-1.38,3.76,3.76,0,0,0,.28-3.44c-.47-.91-1.11-1.38-3-2.2a4.14,4.14,0,0,1-1.6-.93,1.26,1.26,0,0,1,.41-2.07,6.44,6.44,0,0,1,4.49.32l.4.17.21-1c.13-.58.23-1.09.23-1.14s-1-.49-2-.67A13.73,13.73,0,0,0,1652.82,1107.38Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1705.49,1108.41l-.26,1.23c-.14.67-.26,1.23-.26,1.25a4,4,0,0,1-.78,0c-.75,0-.77,0-.8.11s-.09.41-.16.77-.15.69-.16.74,0,.09.74.1l.77,0-.56,2.59a13.46,13.46,0,0,0-.58,3.35c0,.73,0,.77.14,1.05.36.73,1.06,1,2.32,1a3.69,3.69,0,0,0,1.6-.25,14.34,14.34,0,0,0,.35-1.73,1.07,1.07,0,0,0-.24,0,2.39,2.39,0,0,1-1.23,0,.83.83,0,0,1-.43-.72c0-.09.25-1.32.55-2.73s.54-2.58.54-2.59a9.06,9.06,0,0,1,1.1,0h1.1l.17-.78c.09-.43.17-.81.17-.84s-.26-.07-1.07-.08l-1.08,0,.25-1.16c.13-.65.25-1.21.25-1.27s-.1-.08-1.22-.08C1706,1108.39,1705.49,1108.4,1705.49,1108.41Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1682.43,1110.75a5.43,5.43,0,0,0-4.29,3.92,5.21,5.21,0,0,0-.25,2,3.91,3.91,0,0,0,3,3.91,4.14,4.14,0,0,0,1.39.14,6.29,6.29,0,0,0,1.33-.09,8.21,8.21,0,0,0,1.43-.43l.19-.1.22-1c.12-.55.21-1,.21-1l-.46.17a5.23,5.23,0,0,1-3.54.33,2.19,2.19,0,0,1-1.37-1.9,1.24,1.24,0,0,1,0-.36s1.48,0,3.26,0h3.23l.07-.38a8.65,8.65,0,0,0,.06-1.34,3.93,3.93,0,0,0-.42-2.17,3.51,3.51,0,0,0-1.28-1.34A4.83,4.83,0,0,0,1682.43,1110.75Zm1.39,1.92a1.67,1.67,0,0,1,.78.93,4.65,4.65,0,0,1,.11.71l0,.46h-4.32l.06-.2a3.16,3.16,0,0,1,.76-1.31,2.32,2.32,0,0,1,1.86-.76A1.5,1.5,0,0,1,1683.82,1112.67Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1673.26,1110.77a4,4,0,0,0-1.76.86l-.31.28.06-.32c0-.18.08-.4.1-.49l0-.17h-1.2l-1.2,0-1.39,6.48c-.77,3.57-1.39,6.52-1.4,6.56s.28.06,1.21.06c1.12,0,1.22,0,1.25-.09s.24-1.07.5-2.27.48-2.21.5-2.25.07,0,.17.15a3.13,3.13,0,0,0,3.54,1c2.12-.54,3.62-2.65,3.89-5.45.2-2.16-.58-3.77-2.1-4.3a2.85,2.85,0,0,0-1-.15A6.33,6.33,0,0,0,1673.26,1110.77Zm.57,2a1.51,1.51,0,0,1,.77.88,5.63,5.63,0,0,1-.09,2.89c-.45,1.58-1.38,2.39-2.65,2.33-.83,0-1.26-.42-1.5-1.35a6.17,6.17,0,0,1,0-2.1c.41-1.79,1.55-2.9,2.88-2.79A2,2,0,0,1,1673.83,1112.76Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1698.27,1110.78a5.49,5.49,0,0,0-1.61.56,2.7,2.7,0,0,0-.77,4.14,4.43,4.43,0,0,0,1.62,1c.93.43,1.15.56,1.33.8a.82.82,0,0,1,.1.88c-.17.33-.52.5-1.17.58a5,5,0,0,1-2.84-.58,3.77,3.77,0,0,0-.53-.24s-.45,2.13-.43,2.14a7.92,7.92,0,0,0,1.36.44,6.2,6.2,0,0,0,4.34-.3,3.16,3.16,0,0,0,1.72-2.07,4.54,4.54,0,0,0,0-.79,2,2,0,0,0-.66-1.5,5.26,5.26,0,0,0-1.78-1.1,8.87,8.87,0,0,1-1.08-.57.72.72,0,0,1,.06-1.29,5,5,0,0,1,3.58.38c.23.09.17.21.43-1a7,7,0,0,0,.17-1,4.62,4.62,0,0,0-1.35-.45A10.76,10.76,0,0,0,1698.27,1110.78Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1713.53,1110.75l-.52.08a10.33,10.33,0,0,0-1.9.49s-.43,2-.43,2,.09,0,.35-.07a7.49,7.49,0,0,1,2.76-.55c1.38,0,2.07.42,2.09,1.27v.33l-.83.08c-3,.31-4.69.93-5.51,2a2.75,2.75,0,0,0,.57,3.82,3.46,3.46,0,0,0,2.16.54,3.24,3.24,0,0,0,1.39-.37,3.38,3.38,0,0,0,1.27-1c.06-.1.12-.17.13-.16a4.92,4.92,0,0,1-.11.61c-.07.32-.13.61-.13.65s.21.07,1.12.07,1.11,0,1.11-.07a28.53,28.53,0,0,1,.65-3.51,14.48,14.48,0,0,0,.61-3.35,2.31,2.31,0,0,0-.27-1.22,2.39,2.39,0,0,0-.87-1,4.37,4.37,0,0,0-2.64-.68Zm2.18,5.17a9,9,0,0,1-.55,1.51,2.58,2.58,0,0,1-2.55,1.65,1.23,1.23,0,0,1-1.16-1.28c0-1,.94-1.6,3.07-1.86C1715.24,1115.85,1715.71,1115.85,1715.71,1115.92Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1693.66,1111a3.65,3.65,0,0,0-1.83,1.23l-.29.33s.12-.59.32-1.48l0-.11h-1.22a11.32,11.32,0,0,0-1.22,0l-1,4.75c-.55,2.61-1,4.76-1,4.78a9.85,9.85,0,0,0,1.22,0c1.11,0,1.22,0,1.24-.09s.28-1.25.57-2.66c.57-2.69.65-3,.93-3.48a2.4,2.4,0,0,1,2.61-1.15,2.69,2.69,0,0,0,.47,0s.11-.48.22-1,.23-1.09.25-1.16,0-.11-.48-.11A3.85,3.85,0,0,0,1693.66,1111Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1725.27,1111a3.62,3.62,0,0,0-1.82,1.23c-.16.18-.29.33-.3.33s.12-.59.32-1.48l0-.11h-1.22a11.32,11.32,0,0,0-1.22,0s-.46,2.14-1,4.75-1,4.75-1,4.78a9.62,9.62,0,0,0,1.21,0c1.12,0,1.22,0,1.25-.09l.57-2.66c.57-2.69.64-3,.92-3.48a2.41,2.41,0,0,1,2.62-1.15,2.62,2.62,0,0,0,.46,0s.11-.48.23-1,.23-1.09.25-1.16,0-.11-.49-.11A4,4,0,0,0,1725.27,1111Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1658.64,1111c-.24,1-1.28,6.08-1.33,6.44-.21,1.74.41,2.86,1.82,3.21a4.1,4.1,0,0,0,.91.08,2.78,2.78,0,0,0,1-.13,3.52,3.52,0,0,0,1.45-.82c.21-.19.37-.3.37-.26s0,.27-.1.52l-.09.45h1.23c.69,0,1.24,0,1.24,0s1.95-9.24,2-9.47l0-.11h-2.47l-.56,2.66c-.6,2.8-.75,3.38-1.06,3.93a2.79,2.79,0,0,1-1.06,1.07,2.32,2.32,0,0,1-1.3.18,1,1,0,0,1-.88-.88c-.08-.41,0-.8.66-4,.32-1.56.59-2.86.59-2.9s-.28-.06-1.22-.06h-1.22Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1637.05,1102.27c-.17.52-.42,1.27-.54,1.66l-.23.72h-3.47l1.39,1,1.4,1s-.23.75-.52,1.64-.53,1.64-.52,1.65.63-.44,1.39-1,1.4-1,1.41-1,.65.45,1.41,1,1.39,1,1.4,1l-.53-1.66c-.31-.95-.53-1.65-.51-1.66l1.4-1,1.38-1h-1.74l-1.73,0-.53-1.61c-.29-.89-.53-1.63-.54-1.65A9.22,9.22,0,0,0,1637.05,1102.27Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1631,1104.73a7.85,7.85,0,0,0-4.42,2.07,6,6,0,0,0-1.71,3.57c0,.15,0,.53,0,.94a5.64,5.64,0,0,0,.06,1,4.9,4.9,0,0,0,1.46,2.93A8.59,8.59,0,0,0,1629,1117a19.9,19.9,0,0,0,2.22.87,32.69,32.69,0,0,1,3.95,1.56c1.56.78,2.35,1.54,2.61,2.53a3.82,3.82,0,0,1,0,1.09,4,4,0,0,1-1.11,2.42,4.46,4.46,0,0,1-1.79,1.14c-.37.13-.28.15.26.07a7.47,7.47,0,0,0,3.46-1.52,6,6,0,0,0,2.11-4.49,5.82,5.82,0,0,0-.66-3.24,4.69,4.69,0,0,0-.8-1.06,8.54,8.54,0,0,0-2.52-1.76,26.39,26.39,0,0,0-3.3-1.34c-2.88-1-4.1-1.65-4.85-2.39a2.61,2.61,0,0,1-.82-2,3.75,3.75,0,0,1,1-2.67,5.35,5.35,0,0,1,2.25-1.33c.35-.11.34-.1.33-.14A1.43,1.43,0,0,0,1631,1104.73Z"
          transform="translate(-1624.87 -1101.37)"
        />
        <path
          d="M1625.23,1123.39a24.61,24.61,0,0,0,0,3.46c.07,0,7.45-3.43,7.48-3.45s-7.23-3.38-7.49-3.48C1625.23,1119.91,1625.23,1120.4,1625.23,1123.39Z"
          transform="translate(-1624.87 -1101.37)"
        />
      </svg>
    </Link>
  );
};
