import classnames from 'classnames';
import { MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import { useThemeConfig } from '../../../lib/state/GlobalStateContext';

export interface ButtonProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
  variant?: 'normal' | 'style' | 'outline' | 'soft' | 'link';
  colorScheme?: 'theme' | 'dark' | 'light';
  classNames?: string[];
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  isDisabled?: boolean;
  isLoading?: boolean;
  isPressed?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
  className?: string;
  id?: string;
}
export default function Button({
  children,
  variant = 'normal',
  colorScheme = 'theme',
  classNames = [],
  size = 'md',
  onClick,
  type = 'button',
  isDisabled = false,
  isLoading = false,
  ref,
  icon,
  className,
  id,
}: ButtonProps) {
  const height =
    size === 'sm'
      ? 'h-10'
      : size === 'md'
      ? 'h-11'
      : size === 'xs'
      ? 'h-8'
      : 'h-14';
  const themeConfig = useThemeConfig();

  const iconSize = size === 'xs' ? 'h-4 w-4' : '';

  const loadingIcon = (color: string) =>
    isLoading ? (
      <svg
        className={twMerge('animate-spin -ml-1 mr-3 h-6 w-6', color, iconSize)}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : null;
  if (variant === 'style') {
    return (
      <button
        ref={ref}
        id={id ? id : ''}
        className={classnames(
          'rounded-md relative inline-flex items-center group  justify-center cursor-pointer border-b-4 border-l-2  active:shadow-none shadow-lg bg-gradient-to-tr text-white',
          colorScheme === 'theme'
            ? 'active:border-primary-600 from-primary-600 to-primary-500 border-primary-700 disabled:opacity-60'
            : '',
          'disabled:cursor-not-allowed',
          isLoading ? 'cursor-not-allowed' : '',
          size === 'sm'
            ? 'h-10'
            : size === 'md'
            ? 'h-11'
            : size === 'xs'
            ? 'h-8'
            : 'h-14',
          classNames,
          className
        )}
        type={type}
        disabled={isDisabled || isLoading}
        onClick={onClick}
      >
        {!isDisabled && (
          <span className="absolute top-0 left-0 w-full h-0 transition-all duration-300 ease-out bg-white group-hover:w-full group-hover:h-full opacity-10"></span>
        )}
        {loadingIcon('text-white')}
        <span className="relative text-md font-medium">{children}</span>
      </button>
    );
  } else if (variant === 'outline') {
    return (
      <button
        ref={ref}
        id={id ? id : ''}
        type={type}
        className={classnames(
          'rounded-md relative inline-flex items-center justify-center  bg-white transition-colors hover:shadow px-3',
          'text-md font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100  disabled:bg-slate-50 disabled:text-gray-500 disabled:cursor-not-allowed',
          'gap-1',
          size === 'sm'
            ? 'h-10'
            : size === 'md'
            ? 'h-11'
            : size === 'xs'
            ? 'h-8'
            : 'h-14',
          isLoading ? 'cursor-not-allowed' : '',
          ...classNames,
          className
        )}
        onClick={onClick}
        disabled={isDisabled || isLoading}
      >
        {loadingIcon('text-gray-500')}
        {icon}
        <span className="relative text-md font-medium">{children}</span>
      </button>
    );
  } else if (variant === 'soft') {
    const bg =
      (colorScheme === 'theme' && themeConfig.softButton) ||
      (colorScheme === 'dark' && 'bg-gray-900 hover:bg-gray-700') ||
      (colorScheme === 'light' && 'bg-gray-50 hover:bg-gray-100') ||
      '';
    const textColor =
      (colorScheme === 'theme' && 'text-white') ||
      (colorScheme === 'dark' && 'text-white') ||
      (colorScheme === 'light' && 'text-gray-900') ||
      '';
    return (
      <button
        id={id ? id : ''}
        type="button"
        className={classnames(
          'rounded-md  px-3.5 py-2.5 text-sm font-semibold  shadow-sm  inline-flex items-center transition-colors gap-0.5 border ',
          bg,
          textColor,
          height,
          isLoading ? 'cursor-not-allowed' : ''
        )}
        onClick={onClick}
      >
        {loadingIcon(textColor)}
        {icon}
        <span className="relative text-base font-medium">{children}</span>
      </button>
    );
  } else if (variant === 'link') {
    return (
      <a
        className={twMerge(
          'text-slate-600 hover:text-gray-900 transition-colors cursor-pointer font-medium hover:underline hover:underline-offset-4 gap-2 items-center',
          height,
          classNames,
          icon ? 'flex' : 'inline-flex h-6',
          className
        )}
        onClick={(e) => onClick && onClick(e as any)}
      >
        {icon}
        {children}
      </a>
    );
  } else {
    return (
      <button
        id={id ? id : ''}
        ref={ref}
        type="button"
        className={classnames(
          'rounded-md relative inline-flex items-center justify-center transition-opacity px-4 py-1 text-md font-semibold text-white shadow group overflow-hidden hover:shadow-md disabled:cursor-not-allowed',
          size === 'sm'
            ? 'h-10'
            : size === 'md'
            ? 'h-11'
            : size === 'xs'
            ? 'h-8'
            : 'h-14',
          themeConfig.normalButton,
          isLoading ? 'cursor-not-allowed' : '',
          ...classNames,
          className
        )}
        onClick={onClick}
        disabled={isDisabled || isLoading}
      >
        {loadingIcon('text-white')}
        {icon}
        <span className="relative text-md font-medium">{children}</span>
        <span className="absolute w-0 h-full left-0 bottom-0 transition-all duration-300 ease-out rounded-lg group-hover:w-full group-hover:h-full bg-gradient-to-r from-slate-50 to-slate-500 opacity-10"></span>
      </button>
    );
  }
}
