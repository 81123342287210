import { HTMLAttributes, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;
export function VStack({ children, className, ...props }: Props) {
  return (
    <div className={twMerge('flex flex-col', className)} {...props}>
      {children}
    </div>
  );
}
